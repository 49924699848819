import React, { useEffect } from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'

interface PageProps {
  data: {
    page: {
      title?: string
      path?: string
      seo: any
      flexContent: any
    }
  }
  location?: any
  prefix?: string
  pageContext: any
}

const PageTemplate: React.FC<PageProps> = ({
  data: {
    page: { flexContent, seo },
  },
  location = {},
  prefix = 'page_Flexcontent',
  pageContext,
}) => {
  useEffect(() => {
    if (location.hash && typeof document !== 'undefined') {
      const id = location.hash.replace('#', '')

      // No fear setTimeout is here.
      setTimeout(() => {
        document.getElementById(id)?.scrollIntoView()
      }, 10)
    }
  }, [location.hash])

  return (
    <Layout>
      <SEO seo={seo} />
      <FlexContentHandler
        prefix={prefix}
        fields={flexContent}
        location={location}
        pageContext={pageContext}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query pageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      ...generalPageFragment
    }
  }
`

export default PageTemplate
